const openBtn = document.getElementById("open-btn");
const closeBtn = document.getElementById("close-btn");
const menu = document.getElementById("menu");
const body = document.querySelector("body");
const media = window.matchMedia("(width < 1200px)");

function setupMenu(e) {
  if (e.matches) {
  } else {
    menu.style.transition = "none";
    closeMobileMenu();
  }
}

function openMobileMenu() {
  openBtn.setAttribute("aria-expanded", "true");
  menu.removeAttribute("style");
  body.classList.add("scroll-lock");
}

function closeMobileMenu() {
  openBtn.setAttribute("aria-expanded", "false");
  body.classList.remove("scroll-lock");

  setTimeout(() => {
    menu.style.transition = "none";
  }, 500);
}

setupMenu(media);

openBtn.addEventListener("click", openMobileMenu);
closeBtn.addEventListener("click", closeMobileMenu);
